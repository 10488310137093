


#cssportal-grid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  width: 100%;
  height: 100%;
}
#div2 {
  grid-area: 1/1/2/2;


}
#div3 {
  grid-area: 1 / 2 / 2 / 4;


}
#div4 {
  grid-area: 2/1/3/2;
max-height: 200px;
}
#div5 {
  grid-area: 2/2/3/3;

}
#div6 {
  grid-area: 2/3/3/4;

}
.card{
  display: flex;
  width: 100%;
}
.card-block{
  width: 100%;
  margin: 10px;
  max-width: 350px;
}

summary {
  background-color: #0F6CBD;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}

details[open] summary {border-radius: 5px 5px 0 0;}

details {

  border-radius: 10px;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 150px;
  max-height: 150px;
}

.circle-bg {
  fill: none;
  stroke: #ececec;
  stroke-width: 4;
}

.percentage {
  fill: #000000;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-anchor: middle;
}

.circle {
  fill: none;
  stroke-width: 4;
  /* stroke-linecap: round; */
  stroke: #0F6CBD;
  transform-origin: 50% 50%;

}

.dx-datagriddash .dx-row > td {
  padding: 2px 10px;
  font-size: 13px;
  line-height: 15px;
}
.padd_raz{
   padding: 10px 10px;
 }

